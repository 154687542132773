<template>
  <div class="center-box">
    <div class="user row-between mb10">
      <div class="user-info row">
        <div class="info-avatar pr" @click="dialogVisible = true">
          <!-- <el-image :src="userInfo.avatar" fit="cover"></el-image> -->
          <!-- <el-upload style="width: 90px; height: 90px" :action="`${download}/file/formimage`"
            :headers="{ token: $store.getters.userInfo.token }" :on-success="successUpload" :show-file-list="false"> -->
          <!-- <img :src="ruleForm.avatar" class="avatar" /> -->
          <el-image
            class="avatar"
            :src="ruleForm.avatar"
            fit="cover"
            v-if="ruleForm.avatar"
          ></el-image>
          <!-- </el-upload> -->
          <div class="tips pa xxs white">资料修改</div>
        </div>
        <div class="info-content">
          <div class="name lg mb10 lighter">{{ ruleForm.nickname }}</div>
          <div class="company nr muted">
            {{
              (userCenterData.company_info &&
                userCenterData.company_info.company_name) ||
              "资质审核中"
            }}
          </div>
        </div>
      </div>
      <el-dialog
        title="资料修改"
        :visible.sync="dialogVisible"
        width="600px"
        @close="userAvatar = ''"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="80px"
        >
          <el-form-item label="用户头像" prop="avatar">
            <el-upload
              style="width: 90px; height: 90px"
              :action="`${download}/file/formimage`"
              class="info-avatar"
              :headers="{ token: $store.getters.userInfo.token }"
              :on-success="successUpload"
              :show-file-list="false"
            >
              <el-image
                class="avatar"
                :src="userAvatar || ruleForm.avatar"
                fit="cover"
                v-if="ruleForm.avatar"
              >
              </el-image>
            </el-upload>
          </el-form-item>
          <el-form-item label="用户昵称" prop="nickname">
            <el-input
              v-model="ruleForm.nickname"
              placeholder="请填写用户昵称"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户性别" prop="sex">
            <el-radio-group v-model="ruleForm.sex">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="setUserInfo">确 定</el-button>
        </span>
      </el-dialog>
      <div class="lines" v-if="this.$store.getters.userInfo.company_id !== 61">
        <div class="name mb20 md" >
          我的额度{{
            userCenterData.company_info &&
            !userCenterData.company_info.credit_lines
              ? "(未开通)"
              : ""
          }}{{
            userCenterData.company_info &&
            userCenterData.company_info.credit_lines &&
            userCenterData.company_info.isEnable == 0
              ? "(已冻结)"
              : ""
          }}
        </div>
        <div @click="gotoyuemx()" style="cursor: pointer">
          <make-priceformat
            :price="
              userCenterData.company_info &&
              userCenterData.company_info.sy_quota
            "
            :subscriptSize="36"
            :firstSize="48"
            :secondSize="48"
            color="#333333"
          ></make-priceformat>
        </div>
      </div>
    </div>
    <div class="order-box" v-if="userInfo.company_id != 61 || (userInfo.company_id == 61 && userInfo.group_id == 1)">
      <div class="order-title lg lighter">我的订单</div>
      <div class="order-list row wrap">
        <router-link to="/orderlist" class="order-item row-center">
          <div class="column-center pr">
            <img src="@/assets/icon/icon-all.png" alt="" />
            <p class="item-title">全部订单</p>
          </div>
        </router-link>
        <router-link to="/orderlist?type=pay" class="order-item row-center">
          <div class="column-center pr">
            <img src="@/assets/icon/icon-payment.png" alt="" />
            <p class="item-title">待付款</p>
            <el-badge
              :value="statusNumber.pay"
              :hidden="!statusNumber.pay"
            ></el-badge>
          </div>
        </router-link>
        <router-link
          to="/orderlist?type=delivery"
          class="order-item row-center"
        >
          <div class="column-center pr">
            <img src="@/assets/icon/icon-goods.png" alt="" />
            <p class="item-title">待收货</p>
            <el-badge
              :value="statusNumber.delivery"
              :hidden="!statusNumber.delivery"
            ></el-badge>
          </div>
        </router-link>
        <router-link to="/orderlist?type=examine" class="order-item row-center">
          <div class="column-center pr">
            <img src="@/assets/icon/icon-audit.png" alt="" />
            <p class="item-title">待审核</p>
            <el-badge
              :value="statusNumber.examine"
              :hidden="!statusNumber.examine"
            ></el-badge>
          </div>
        </router-link>
        <router-link to="/saleslist" class="order-item row-center">
          <div class="column-center pr">
            <img src="@/assets/icon/icon-service1.png" alt="" />
            <p class="item-title">售后订单</p>
            <el-badge
              :value="statusNumber.after_ing"
              :hidden="!statusNumber.after_ing"
            ></el-badge>
          </div>
        </router-link>
        <router-link to="/evaluatelist" class="order-item row-center">
          <div class="column-center pr">
            <img src="@/assets/icon/icon-sign.png" alt="" />
            <p class="item-title">待评价</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { vxRule } from "@/utils/validator";
import Cache from "@/utils/cache";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      userCenterData: {},
      download: process.env.VUE_APP_DOWNLOAD_URL,
      dialogVisible: false,
      ruleForm: {},
      statusNumber: {},
      userAvatar: "",
      rules: {
        nickname: vxRule(true, null, "blur", "请填写用户昵称"),
        sex: vxRule(true, null, "blur", "请选择用户性别"),
      },
      userInfo: {}, //用户信息
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {},
  methods: {
    /**
     * @description 获取我的订单数据
     */
    getStatusNumber() {
      this.$api.getStatusNumberApi().then((res) => {
        this.statusNumber = res.data;
      });
    },
    // 获取用户信息
    getUserCenter() {
      this.$api.getUserCenterApi().then((res) => {
        this.userCenterData = res.data;
      });
    },
    gotoyuemx() {
      this.$router.push({ path: "/yuelist" });
    },
    successUpload(res) {
      let { code, data } = res;
      this.userAvatar = res.data.uri;
      // if (code == 1) {
      //   this.$api
      //     .setUserInfoApi({
      //       field: "avatar",
      //       value: data.uri,
      //     })
      //     .then((res) => {
      //       let { code } = res;
      //       if (code == 1) {
      //         this.$message({
      //           message: "修改成功",
      //           type: "success",
      //         });
      //         this.getUserInfo();
      //       }
      //     });
      // }
    },
    getUserInfo() {
      this.$api.getUserInfoApi().then((res) => {
        if (res.data?.sex == "未知") {
          res.data.sex = "";
        }
        this.ruleForm = res.data;
      });
    },
    setUserInfo() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (!valid) return;
        this.$api
          .updateUserInfoApi({
            ...this.ruleForm,
            sex: this.ruleForm.sex == "男" ? 1 : 2,
            avatar: this.userAvatar || this.ruleForm.avatar,
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.getUserInfo();
              this.dialogVisible = false;
            }
          });
      });
    },
  },
  created() {
    this.userInfo = Cache.get("userInfo")
    this.getUserCenter();
    this.getUserInfo();
    this.getStatusNumber();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.center-box {
  .info-avatar {
    width: 90px;
    height: 90px;
    margin-right: 25px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;

    .avatar {
      width: 90px;
      height: 90px;
    }

    .tips {
      bottom: 0;
      height: 25px;
      left: 0;
      right: 0;
      line-height: 20px;
      text-align: center;
      background-color: rgba($color: #000000, $alpha: 0.6);
    }
  }

  .user {
    background-color: $-color-center;
    padding: 35px;

    .user-info {
      /deep/ .el-upload {
        width: 100%;
        height: 100%;
        background-color: $-color-center;
        border: none;

        img {
          object-fit: cover;
        }
      }
    }

    .lines {
      text-align: center;
    }
  }

  .order-box {
    border: $-solid-border;
    padding: 0 20px 25px;

    .order-title {
      line-height: 70px;
      height: 70px;
    }

    .order-list {
      .order-item {
        width: 282px;
        height: 207px;
        margin-bottom: 3px;
        margin-right: 3px;
        background-color: $-color-center;
        position: relative;

        img {
          width: 80px;
          height: 80px;
          margin-bottom: 15px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        p {
          font-size: 25px;
          color: $-color-lighter;
        }

        /deep/ .el-badge {
          position: absolute;
          top: 0;
          right: 0;

          .el-badge__content {
            background-color: #dd0000;
            font-size: 15px;
            height: 27px;
            line-height: 27px;
            border-radius: 50%;
            padding: 0 4px;
            border: none;
            min-width: 27px;
          }
        }
      }
    }
  }

  /deep/ .el-dialog__body {
    padding: 30px 40px;
  }
}
</style>
